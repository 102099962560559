@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
:root{
    --primary: #7a2396;

    /* font weight */
    --bold:600;
    --bolder:900;
    --normal:500;
    --font-robo:'Poppins', sans-serif;
    --defaultText:12px;
    --link-color:#777;
    --b-radius:10px;
    --blue:#0a58ca;
    --black:#000;
    --white:#fff;
    --slide-blue:#5bb1e3;
    --border-color:  #ced4da;
}