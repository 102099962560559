@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css');

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: var(--font-robo);
  font-size: var(--defaultText);
  padding: 0 0 20px 0;
  overflow: auto;
  background: #fff;
  /* background: #f7fc8a;
  background: -webkit-linear-gradient(to top, #f7fc8a, #cccccc);
  background: linear-gradient(to top, #f7fc8a, #ffffff); */
}
.lazy-loading {
  width: 100%;
  background-color: #fff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
}
button,
input,
optgroup,
select,
textarea {
  font-family: var(--font-robo);
}
.wb {
  font-weight: var(--bold);
}
.bradius {
  border-radius: var(--b-radius);
}
.input-group-lg > .btn,
.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text {
  border-radius: var(--b-radius);
}
.app-footer {
  width: 100%;
  height: 40px;
  bottom: 0;
  left: 0;
  background: #fff;
}

#login {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #fff;
  height: 100vh;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

* {
  box-sizing: border-box;
  list-style-type: none;
  font-family: var(--font-robo);
}

ul {
  list-style: none;
}
a {
  text-decoration: none;
  color: inherit;
}
img {
  display: block;
  max-width: 100%;
  height: auto;
}
a,
button {
  cursor: default;
}
a:hover,
a:active,
.active {
  color: var(--primary) !important;
}
.text-primary {
  color: var(--primary) !important;
}
p {
  font-size: 13px;
  line-height: 25px;
}
button {
  color: inherit;
  background: transparent;
  border: none;
  outline: none;
}
.no-transition {
  transition: none !important;
}
/* login */
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.form-signin input {
  font-size: var(--defaultText);
}

.btn {
  font-size: var(--defaultText);
  line-height: 1.75rem;
}

.text-primary {
  color: var(--primary) !important;
  font-weight: var(--bold);
}
.btn-primary {
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
}
.btn-primary.disabled,
.btn-primary:disabled,
.btn-primary:active,
.btn-primary:focus {
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-primary:hover,
.btn-check:focus + .btn-primary,
.btn-primary:focus,
.btyn-primar:active,
.btyn-primar:focus-visible {
  background-color: var(--primary);
  border-color: var(--primary);
  opacity: 0.9;
  box-shadow: none !important;
  outline: 0;
}

.btn-link {
  text-decoration: none;
  font-weight: var(--bold);
  color: var(--link-color);
  font-size: 12px;
}
.btn-link:hover {
  color: var(--primary);
}

.btn-outline-primary {
  color: var(--primary);
  border-color: var(--primary);
  font-weight: var(--bold);
}
.btn-outline-primary:hover {
  color: #fff !important;
  background-color: var(--primary);
  border-color: var(--primary);
  font-weight: var(--normal);
}
.btn-check:active + .btn-outline-primary:focus,
.btn-check:checked + .btn-outline-primary:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.btn-outline-primary:active:focus,
.btn-outline-primary:active .btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus,
.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none !important;
}
.form-control:focus {
  border-color: #ced4da;
  outline: 0;
  box-shadow: none;
}
.form-control {
  font-size: var(--defaultText) !important;
  line-height: 1.75rem;
}

/* pages style */
.app-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
/* side nav menu */
header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
.page-header {
  position: fixed;
  padding: 1rem 1.5rem;
  background: #fff;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  /* background: #fefeeb; */
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.page-header .navbar {
  display: flex;
  flex-direction: row;
  /* flex: 1; */
  flex-basis: auto;
  align-items: center;
  justify-content: space-between;
}
.page-header .navbar .vertical-logo {
  font-size: 1.7rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #d93622;
}
.page-header .navbar .top-menu-wrapper {
  color: #221f1f;
}
.page-header .navbar .open-mobile-menu i {
  color: #221f1f;
}
.page-header .navbar .top-menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  transform: translate3d(-100%, 0, 0);
  transition: transform 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  transition-duration: 0.7s;
}
.page-header .navbar .top-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  padding: 1.5rem 2rem;
  background: #fff;
  height: 100%;
}
.page-header .navbar .top-menu-wrapper.show-offcanvas::before {
  background: #fff;
  z-index: 1;
}
.page-header .navbar .top-menu-wrapper.show-offcanvas .panel,
.page-header .navbar .top-menu-wrapper.show-offcanvas .top-menu {
  transform: translate3d(0, 0, 0);
  transition-duration: 0.7s;
}
.page-header .navbar .top-menu-wrapper.show-offcanvas .top-menu {
  transition-delay: 0.2s;
}
.page-header .navbar ul a {
  display: inline-block;
  font-size: 1rem;
  font-weight: 600;
  transition: color 0.35s ease-out;
}
.page-header .navbar ul a:hover {
  color: var(--primary);
}
.page-header .navbar .has-dropdown i {
  display: none;
}
.page-header .navbar .sub-menu {
  padding: 0.5rem 1.5rem 0 1.5rem;
}
.page-header .navbar .sub-menu a {
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: 400;
  margin-top: 0rem;
}
.page-header .navbar .top-menu li + li {
  margin-top: 2.5rem;
}
.page-header .navbar .top-menu > li:last-child {
  margin-top: auto;
  margin-bottom: 50px;
}
.page-header .navbar .top-menu .mob-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.page-header .navbar .top-menu .mob-block .logo {
  font-size: 1.7rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #d93622;
}
.page-header .navbar .top-menu .mob-block i {
  color: #221f1f;
}
.close-btn {
  font-size: 32px;
  color: var(--link-color);
  opacity: 0.5;
}
.app-user {
  display: flex;
  height: 40px;
  align-items: center;
  font-size: 14px;
}
.user-img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--primary);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
}
.logout {
  font-size: 15px !important;
  color: var(--link-color);
}
.logout .bi {
  font-size: 20px;
  position: relative;
  top: 3px;
}
.logout img {
  opacity: 0.7;
}
/* end side nav menu */
main {
  position: relative;
  height: auto;
  overflow: auto;
  padding: 65px 0;
}
footer {
  width: 100%;
}
.f-nav {
  width: 100%;
  height: 65px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  /* background-color: #fefeeb; */
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
footer nav ul {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 0;
}

footer nav ul li {
  padding: 10px;
}
footer nav ul li a {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
}
footer nav ul li a span {
  margin-top: 4px;
  font-size: 11px;
  font-weight: var(--bold);
}
footer nav ul li a.active span,
footer nav ul li a:hover span {
  color: var(--primary);
}
footer nav ul li a .homeIcon {
  position: relative;
  display: inline-block;
  width: 22px;
  height: 22px;
  -webkit-mask-image: url('../assets/icons/005-home.png') no-repeat;
  -webkit-mask: url('../assets/icons/005-home.png') no-repeat;
  -webkit-mask-size: 22px 22px;
  background: var(--black);
}
footer nav ul li a.active .homeIcon,
footer nav ul li a:hover .homeIcon {
  background: var(--primary);
}
footer nav ul li a .compassIcon {
  position: relative;
  display: inline-block;
  width: 22px;
  height: 22px;
  -webkit-mask-image: url('../assets/icons/006-compass.png') no-repeat;
  -webkit-mask: url('../assets/icons/006-compass.png') no-repeat;
  -webkit-mask-size: 22px 22px;
  background: var(--black);
}
footer nav ul li a.active .compassIcon,
footer nav ul li a:hover .compassIcon {
  background: var(--primary);
}
footer nav ul li a .starIcon {
  position: relative;
  display: inline-block;
  width: 22px;
  height: 22px;
  -webkit-mask-image: url('../assets/icons/007-star.png') no-repeat;
  -webkit-mask: url('../assets/icons/007-star.png') no-repeat;
  -webkit-mask-size: 22px 22px;
  background: var(--black);
}
footer nav ul li a.active .starIcon,
footer nav ul li a:hover .starIcon {
  background: var(--primary);
}
footer nav ul li a .bagIcon {
  position: relative;
  display: inline-block;
  width: 22px;
  height: 22px;
  -webkit-mask-image: url('../assets/icons/008-bag.png') no-repeat;
  -webkit-mask: url('../assets/icons/008-bag.png') no-repeat;
  -webkit-mask-size: 22px 22px;
  background: var(--black);
}
.bagIcon {
  position: relative;
  display: inline-block;
  width: 22px;
  height: 22px;
  -webkit-mask-image: url('../assets/icons/008-bag.png') no-repeat;
  -webkit-mask: url('../assets/icons/008-bag.png') no-repeat;
  -webkit-mask-size: 22px 22px;
  background: var(--black);
}
.binIcon {
  position: relative;
  display: inline-block;
  width: 22px;
  height: 22px;
  -webkit-mask-image: url('../assets/icons/003-bin.png') no-repeat;
  -webkit-mask: url('../assets/icons/003-bin.png') no-repeat;
  -webkit-mask-size: 22px 22px;
  background: var(--black);
}
.downloadIcon {
  position: relative;
  display: inline-block;
  width: 22px;
  height: 22px;
  -webkit-mask-image: url('../assets/icons/004-download.png') no-repeat;
  -webkit-mask: url('../assets/icons/004-download.png') no-repeat;
  -webkit-mask-size: 22px 22px;
  background: var(--blue);
}
.starIcon {
  position: relative;
  display: inline-block;
  width: 22px;
  height: 22px;
  -webkit-mask-image: url('../assets/icons/007-star.png') no-repeat;
  -webkit-mask: url('../assets/icons/007-star.png') no-repeat;
  -webkit-mask-size: 22px 22px;
  background: var(--link-color);
}
.icon-star,
.fill-star {
  line-height: 0;
}
.fill-star i {
  font-size: 22px;
  color: #ecab1a;
}
footer nav ul li a.active .bagIcon,
footer nav ul li a:hover .bagIcon,
a.active .bagIcon,
a:hover .bagIcon {
  background: var(--primary);
}
.right-arrow {
  font-size: 28px;
  color: #000;
}
.fs16 {
  font-size: 16px !important;
}
/* slider */
.caSlider .slick-list {
  padding-left: 0 !important;
}
.slick-track {
  margin-left: 0 !important;
}
.slick-slider .item {
  border-radius: 16px;
  overflow: hidden;
  height: 160px;
  position: relative;
  width: calc(100% - 14px) !important;
  margin-left: 7px;
  margin: 10px 0;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.slick-slider .item:focus,
.slick-slider .item:focus-visible,
.slick-slider .c-item:focus,
.slick-slider .c-item:focus-visible,
.slick-slider .a-item:focus,
.slick-slider .a-item:focus-visible {
  outline: 0 !important;
}
.slick-slider .item img {
  /* object-fit: cover; */
  object-fit: fill;
  object-position: top center;
  width: 100%;
  height: 100%;
}
.slick-slider .item .mov-title {
  position: absolute;
  top: auto;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 100%;
  bottom: 10px;
}
.slick-slider .item .mov-title h3 {
  color: var(--white);
  font-weight: var(--bold);
  font-size: 25px;
}
.slick-slider .item .mov-title h4 {
  color: var(--slide-blue);
  font-weight: var(--bold);
  font-size: 26px;
}
.c-item,
.a-item {
  width: calc(100% - 20px) !important;
}
.c-item img,
.a-item img {
  border-radius: 7px;
}
.c-detais,
.a-detais {
  padding: 7px 4px;
  font-weight: var(--bold);
  display: block;
}
.c-detais p,
.a-detais p {
  line-height: 15px;
  word-break: break-word;
}
.mov-name {
  font-size: 11px;
  font-weight: var(--bold);
}
.mov-price {
  font-size: 12px;
  color: var(--primary);
  font-weight: var(--bold);
}
/* ******** */
.sec-title,
.page-title {
  font-size: 17px;
  font-weight: var(--bold);
  margin-bottom: 0.5rem;
  line-height: 1.2;
}
.page-title,
.page-title i {
  color: var(--primary);
}

.my-account {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  padding: 2.5rem 2rem;
  background: #fff;
  height: 100%;
}
.my-account li {
  font-size: 13px;
  font-weight: var(--bold);
  padding-left: 5px;
}
.my-account li:first-child {
  padding-left: 0;
}
.my-account li strong {
  font-weight: var(--bolder);
}
.my-account li a {
  color: var(--primary);
}
.invalid-feedback {
  font-size: 1rem;
  font-weight: var(--bold);
}
.list-item {
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  border-bottom: 1px solid #ddd;
}
.list-item:first-child {
  padding-top: 0;
}
.list-item:last-child {
  border-bottom: 0;
}
.list-item .iImg {
  max-width: 24%;
  height: auto;
  display: block;
}
.list-item .iImg img,
.iImg img {
  width: 100%;
  border-radius: 7px;
  height: auto;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.list-item .iDetail {
  /* max-width: 50%; */
  height: auto;
  display: block;
}
.iDetail p {
  line-height: 16px;
}
.i-price {
  position: relative;
  width: 58px;
}
.i-price h2 {
  text-align: right;
}

.list-item h2 {
  font-size: 15px;
  font-weight: var(--bold);
  color: #333;
  margin-bottom: 4px;
}
.list-item h4 {
  font-size: 13px;
  font-weight: var(--bold);
  color: #9f9f9f;
}
.list-item p {
  font-size: 0.95rem;
  font-weight: 500;
  color: #686868;
  line-height: 15px;
  letter-spacing: 0.25pt;
}
.alert {
  padding: 0.4rem 1rem;
  border-radius: 12px;
}
.alert-primary {
  color: var(--primary);
  background-color: #ebd1f3;
  border-color: #ebd1f3;
}
.bag-btn {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 10px;
  right: 0;
}
.download-btn {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 6px;
  right: 0;
}
.app-rearch {
  position: relative;
  width: auto;
}
.app-rearch input {
  outline: none;
  background-color: #fff;
  border: none;
  border-radius: 19px;
  padding: 0;
  font-size: 1rem;
  height: 38px;
  width: 0px;
  box-sizing: border-box;
  transition: all 0.7s ease-in-out;
  position: absolute;
  top: 0;
  right: 2px;
}
.app-rearch input.focused {
  width: calc(100vw - 42px);
  border: 1px solid #ddd;
  padding: 0 19px;
  right: 4px;
}
::-webkit-input-placeholder {
  color: var(--link-color);
}
::-moz-input-placeholder {
  color: var(--link-color);
}
::-ms-input-placeholder {
  color: var(--link-color);
}
::-o-input-placeholder {
  color: var(--link-color);
}
::input-placeholder {
  color: var(--link-color);
}
.clearSearch {
  position: absolute;
  opacity: 0.4;
  top: 8px;
}
.clearSearch:hover {
  opacity: 0.8;
}
.iDetail h2,
.p-price h2 {
  font-size: 15px;
  font-weight: var(--bold);
  color: #333;
  margin-bottom: 4px;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px white inset;
  -webkit-text-fill-color: #333;
}
.input-group-text {
  border-right: 0;
}
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset;
  -webkit-text-fill-color: #333;
}
.thank-you {
  height: 67vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.thank-you .page-title {
  line-height: 30px;
  text-align: center;
  padding: 50px;
}

.synopsis-card {
  display: flex;
  max-width: 84%;
}
.slider-card {
  height: 120px;
}
.fix-img-height {
  height: 100px;
}

.a-item img,
.c-item img,
.slick-slider .item img {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.btn.search-btn {
  box-shadow: none !important;
}

.rti--container.go2761622576 {
  border-radius: 9px !important;
  padding-left: 36px;
  border-top-left-radius: 9px !important;
  border-bottom-left-radius: 9px !important;
  line-height: 1.65rem;
}

.go3450369076 {
  width: 100% !important;
  position: relative;
}
span.general_icon {
  position: absolute;
  left: 12px;
  top: 10px;
  z-index: 3;
}
.go2761622576:focus-within {
  border-color: var(--border-color) !important;
  box-shadow: none !important;
}
.cpx {
  padding-right: 0.7rem !important;
  padding-left: 0.9rem !important;
}

.go2286398182 button:hover {
  color: var(--primary) !important;
}

/* tags */
.tags {
  background: #fff;
  padding: 5px;
  overflow: hidden;
}

.tag {
  color: #000;
  font-weight: 500;
  background: #eee;
  float: left;
  padding: 1px 7px;
  border-radius: 7px;
  margin: 0 0 0 5px;
  font-size: 12px;
}
.tag button {
  background: transparent;
  border: 0;
  cursor: pointer;
}

.tag-input {
  padding: 2px 10px;
  box-sizing: border-box;
  color: #7f8c8d;
  border: 0;
  float: left;
  margin: 0px 0;
  font-size: 16px;
  outline: 0;
  width: 100%;
}
.genralPref .tags {
  padding: 6px 5px 6px 27px;
}
.genralPref .tags ul {
  margin: 0;
  padding: 0;
}

#payment-form {
  height: 100vh;
}
.password_btn {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 100%;
  border-radius: 10px;
  z-index: 3;
}
.password_input {
  border-radius: 0 10px 10px 0px !important;
}

.headerlogo img {
  max-width: 200px;
}
.headerlogo {
  margin: auto;
  text-align: center;
}
.checkbox-block {
  display: flex;
  padding: 0 0 10px 4px;
  float: left;
  padding-top: 7px;
}

.checkbox-keep-me{
margin-left: 5px;
}
